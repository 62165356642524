var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table"},[_c('div',{staticClass:"table-container",class:{
          'normal': _vm.type === 'normal',
          'customers': _vm.type === 'customers',
          'customers-checkbox': _vm.type === 'customers-checkbox',
          'cars': _vm.type === 'cars',
          'feed': _vm.type === 'feed',
          'campaign': _vm.type === 'campaign',
          'campaign_audience': _vm.type === 'campaign_audience',
      },style:({ '--border-color': _vm.borderColor || '#E7E8F2' })},[_c('div',{staticClass:"table-container__header"},[_vm._t("tHead")],2),_c('div',{staticClass:"table-container__body ez-slim-scroll"},[_vm._t("tBody")],2),_vm._t("tFooter")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }